import { Link } from 'gatsby';
import React from 'react';
import Icon from '../Icon';
import * as styles from './Card.module.scss';

interface ICardProps {
    icon: string,
    category: string,
    title: string,
    url: string,
    light?: Boolean,
}

const Card = ({ icon, category, title, url, light = false }: ICardProps) => {
    return (
        <Link className={`${styles.cardMain} ${light ? styles.light : styles.dark}`} to={url}>
            <div className={`${styles.card} ${light ? styles.light : styles.dark}`}>
                <div className={styles.cardHeader}>
                    <Icon icon={icon} style={`${styles.cardIcon} ${light ? styles.lightIcon : styles.darkIcon}`} />
                    <span>{category}</span>
                </div>
                <div className={styles.cardTitle}>{title}</div>
            </div>
        </Link>
    )
}

export default Card;

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Header from '../Header';
import SmallCard from '../SmallCard';
import * as styles from './CategorySection.module.scss';

const CategorySection = () => {
    const data = useStaticQuery(graphql`
        query ActiveCategories {
            allMongodbGeneratorninjaCategories(filter: {active: {eq: true}}) {
            edges {
                node {
                name
                slug
                }
            }
            }
        }
    `);
    const categories = data.allMongodbGeneratorninjaCategories.edges;
    return (
        <div className={styles.categories}>
            <Header type="h2" text="Browse Random Generators by Category" />
            <div className={styles.categoryCards}>
                {categories.map((cat) =>
                    <SmallCard key={cat.node.name} name={cat.node.name} url={cat.node.slug} />
                )}
            </div>
        </div>
    )
}

export default CategorySection;

import React from 'react';
import CategorySection from '../../components/CategorySection';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Icon from '../../components/Icon';
import * as styles from './Contact.module.scss';
import Layout, { FullWidthContainer } from '../../components/Layout';
import PopularSection from '../../components/PopularSection';
import { emailIcon, redditIcon, twitterIcon } from '../../utilities/icons';
import Meta from '../../components/Meta';

const Contact = () => {
    return (
        <Layout>
            <>
                <FullWidthContainer>
                    <>
                        <Meta title="Contact Us" description="Have some thoughts or ideas? Contact Me!" noIndex={true} />
                        <Header type="h1" text="Have some thoughts or ideas? Contact Me!" />
                        <div className={styles.contactSection} >
                            <div className={styles.section}>
                                <Icon icon={emailIcon} />
                                <Header type="h3" text="E-mail Me" />
                                <span>Send me an e-mail to</span><br />
                                <a href="mailto:levi@generator.ninja">levi@generator.ninja</a>
                            </div>
                            <div className={styles.section}>
                                <Icon icon={twitterIcon} />
                                <Header type="h3" text="Tweet or DM Me" />
                                <a href="https://twitter.com/generatorninja">@GeneratorNinja</a>
                            </div>
                            <div className={styles.section}>
                                <Icon icon={redditIcon} />
                                <Header type="h3" text="PM Me on reddit or post on the subreddit" />
                                <a href="https://reddit.com/u/GeneratorNinja">/u/GeneratorNinja</a>
                                <a href="https://reddit.com/r/GeneratorNinja">/r/GeneratorNinja</a>
                            </div>
                        </div>
                    </>
                </FullWidthContainer>
                <PopularSection />
                <CategorySection />
                <Footer />
            </>
        </Layout>
    )
}

export default Contact;

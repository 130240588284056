import { Link } from 'gatsby';
import React from 'react';
import * as styles from './SmallCard.module.scss';

interface ISmallCardProps {
    name: string,
    url: string,
    light?: Boolean,
}

const SmallCard = ({ name, url, light = false }: ISmallCardProps) => {;
    return (
        <Link className={`${styles.smallCardMain} ${light ? styles.light : styles.dark }`} to={url}>
            <div className={`${styles.smallCard} ${light ? styles.light : styles.dark }`}>
                {name}
            </div>
        </Link>
    )
}

export default SmallCard;

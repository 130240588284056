import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Header from '../Header';
import Card from '../Card';
import * as styles from './PopularSection.module.scss';

const PopularSection = () => {
    const data = useStaticQuery(graphql`
        query PopularPages {
            allMongodbGeneratorninjaFixedattrs(
            filter: {active: {eq: true}, popular: {eq: true}}
            ) {
            nodes {
                category
                title
                icon
                slug
            }
            }
            allMongodbGeneratorninjaNocards(
            filter: {active: {eq: true}, popular: {eq: true}}
            ) {
            nodes {
                category
                title
                icon
                slug
            }
            }
            allMongodbGeneratorninjaRandomattrs(
            filter: {active: {eq: true}, popular: {eq: true}}
            ) {
            nodes {
                category
                title
                icon
                slug
            }
            }
        }
    `);
    let pages = [];
    data.allMongodbGeneratorninjaFixedattrs.nodes.forEach((node) => {
        pages.push(node);
    });
    data.allMongodbGeneratorninjaRandomattrs.nodes.forEach((node) => {
        pages.push(node);
    });
    data.allMongodbGeneratorninjaNocards.nodes.forEach((node) => {
        pages.push(node);
    });

    return (
        <div className={styles.popular}>
          <Header type="h2" text="Most Popular Random Generators" light={true} />
          <div className={styles.popularCards}>
            {pages.map((pop) =>
              <Card key={pop.title} title={pop.title} category={pop.category} icon={pop.icon} url={pop.slug} />
            )}
          </div>
        </div>
    )
}

export default PopularSection;
